import { getCompanySubscription } from '../../../helper/subscriptionAction';
import { IProps } from '../../../types';
import { Auth } from 'aws-amplify';
import { useRouter } from 'next/router';
import React from 'react';
import { useEffect } from 'react';
import lemonSqzApis from '../../../helper/lmzsqzapi';
import {
  showErrorToast,
} from '../../Widgets/customToast';

function withAuth<T extends IProps = IProps>(
  WrappedComponent: React.ComponentType<T>
) {
  const AuthenticatedComponent = (props: Omit<T, keyof IProps>) => {
    const router = useRouter();

    useEffect(() => {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          if (user) {
            if (user.attributes['email'].endsWith('@cloudshuttle.com.au')) {
              if (router.asPath === '/'){
                router.replace('/status')
                return
              } else {
                return
              }
            }
            // get subscription id
            getCompanySubscription(user.attributes['custom:companyId'])
            .then((res) => {
              // check if subscription id is valid
              if (res){
                lemonSqzApis.getPaymentSubscription(res.subscription_id)
                .then(res => {
                  // get json object
                  if (res.status === 'expired'){
                    router.replace('/subscribe');
                    showErrorToast('Subscription Expired!')
                    return
                  } else {
                    const isAdmin = user.attributes['custom:isAdmin']??'false';
                    if (isAdmin === 'true' || user.attributes['custom:isPlanValid'] === 'yes'){
                      return
                    } else {
                      router.replace('/askadmin');
                      showErrorToast('Subscribed Plan limit reached!')
                      return
                    }
                  };
              })}
              else {
                router.replace('/subscribe');
                showErrorToast('Subscription required!');
                return
              }
            })
            .catch(err => {
              router.replace('/subscribe');
              showErrorToast('Subscription required!');
              return
            });
          }
          else {
            router.replace('/');
            return;
          }
        })
        .catch((err) => {
          router.replace('/');
          showErrorToast('Login required!')
          return;
        });
    }, []);

    return <WrappedComponent {...props} {...(props as T)} />;
  };

  return AuthenticatedComponent;
}

export default withAuth;