import { trpcClient } from '@/trpc';

const lemonSqzApis = {
  createPaymentCheckout: async (id: string) => {
    const data = await trpcClient.createPaymentCheckout.query({
      id: id
    });

    return JSON.parse(data.return);
  },

  deletePaymentSubscription: async (id: string) => {
    const data = await trpcClient.deletePaymentCheckout.query({
      id: id
    });

    return JSON.parse(data.return);
  },

  getPaymentSubscription: async (id: string) => {
    const data = await trpcClient.getPaymentSubscription.query({
      id: id
    });

    return JSON.parse(data.return);
  },

  resumePaymentSubscription: async (id: string) => {
    const data = await trpcClient.resumePaymentCheckout.query({
      id: id
    });

    return JSON.parse(data.return);
  },

  updatePaymentSubscription: async (
      id: string,
      product_id: string,
      variant_id: string,
    ) => {

    const data = await trpcClient.updatePaymentCheckout.query({
      id: id,
      product_id: product_id,
      variant_id: variant_id
    });

    return JSON.parse(data.return);
  },
}

export default lemonSqzApis;