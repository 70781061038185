import { Container } from './container';
import Link from 'next/link';
import Image from 'next/image';
import statusPageImage from '../public/images/open_lineage_files/status-page.png';
import openlineage from '../public/images/open_lineage_files/open-lineage.svg';

export function Intro() {
  return (
    <Container>
      <div className="py-24 relative bg-white">
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                What is a status page?
              </h3>
              <div className="mt-5 prose prose-indigo text-gray-500">
                <p>Findout the dashboards that are broken</p>
                <p>Get Alerts to your Slack when a pipeline breaks</p>
                <p>Know how much the pipeline costs</p>
                <p>Lineage/Dependancy graph of downstream affects</p>
              </div>
            </div>
          </div>
        </div>
        <div className=" lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0  lg:w-1/2">
            <Image
              className="h-56 w-full object-cover max-h-full lg:absolute lg:h-full"
              src={statusPageImage}
              alt=""
              width={'480'}
              height={'340'}
            />
          </div>
        </div>
      </div>
      <div className="py-24 relative mt-10 bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <Image
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src={openlineage}
              alt=""
              width={'480'}
              height={'340'}
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-1 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                What is Open Lineage?
              </h3>

              <div className="mt-5 prose prose-indigo text-gray-500">
                <p>This is the location where the talks will be held</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-24 relative mt-10 bg-white">
        <div className="lg:absolute lg:inset-0">
          <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
            <Image
              className="h-56 w-full object-cover lg:absolute lg:h-full"
              src="/images/get_started.jpeg"
              alt=""
              width={'480'}
              height={'340'}
            />
          </div>
        </div>
        <div className="relative pt-12 pb-16 px-4 sm:pt-16 sm:px-6 lg:px-8 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2">
          <div className="lg:col-start-2 lg:pl-8">
            <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
              <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Getting Started!
              </h3>
              <div className="mt-5 prose prose-indigo text-gray-500">
                <p>
                  Follow the instructions in this{' '}
                  <Link href="https://github.com/cloud-shuttle/open-lineage-producers">
                    Github Repository
                  </Link>{' '}
                  to get Started on your pipelines!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
